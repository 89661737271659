import {
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import IHomeActive from '@ifca-root/react-component/src/assets/icons/home-w.svg'
import IHome from '@ifca-root/react-component/src/assets/icons/home.svg'
import ILogOutActive from '@ifca-root/react-component/src/assets/icons/logout-w.svg'
import ILogOut from '@ifca-root/react-component/src/assets/icons/logout.svg'
import Logout from 'assets/icons/logout.svg'
import Profile from 'assets/icons/profile.svg'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import Authentication from '@ifca-root/react-component/src/assets/icons/authentication.svg'
export const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  })
)

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  open: boolean
  variant: any
  onCloseDrawer: any
}

export default function LayoutDrawer(props: Props) {
  const { window, open, onCloseDrawer, variant, ...rest } = props
  const classes = useStyles()
  const container =
    window !== undefined ? () => window().document.body : undefined
  let history = useHistory()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const navigations = [
    {
      name: 'Home',
      path: '/home',
      icon: IHome,
      ACLIndex: 0,
    },

    {
      name: 'Logout',
      path: '/logout',
      icon: ILogOut,
      ACLIndex: 0,
    },
  ]

  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const EmployeeDetails = JSON.parse(localStorage.getItem('Employee'))
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem('ACLIndex'))?.length === 0 &&
      !JSON.parse(localStorage.getItem('Employee'))?.EmployeeID
    ) {
      setInterval(() => {
        forceUpdate()
      }, 500)
    }
  }, [])

  const drawer = (
    <div className="route-container">
      <List disablePadding>
        {navigations.map((v, index) => (
          <div key={index}>
            {JSON.parse(localStorage.getItem('ACLIndex'))?.includes(
              v.ACLIndex
            ) || v.ACLIndex === 0 ? (
              <ListItem
                button
                dense
                activeClassName={'active'}
                divider
                component={NavLink}
                to={v.path}
                onClick={onCloseDrawer}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v.icon} alt="drawer-icon" />
                </ListItemIcon>
                <ListItemText primary={v.name} />
              </ListItem>
            ) : null}
          </div>
        ))}
      </List>
    </div>
  )

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        <Avatar alt="avatar" className="avatar" />
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="smTitle">
                { EmployeeDetails?.Contact?.FullName || loggedInUser?.name }
              </span>
            }
            secondary={<span className="desc">{loggedInUser?.email}</span>}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="simple-menu"
              onClick={handleProfileClick}
            >
              <ExpandMore />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/profile')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/profile')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Profile} alt="" /> Profile
        </MenuItem>

        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/profile/change-password')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/profile/change-password')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Authentication} alt="" />{' '}
          Change Password
        </MenuItem>

        <MenuItem
          className="drawer-dropdown"
          onClick={
            isDesktop
              ? () => {
                  history.push('/login')
                  setAnchorEl(null)
                }
              : () => {
                  history.push('/login')
                  setAnchorEl(null)
                  onCloseDrawer()
                }
          }
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" /> Logout
        </MenuItem>
      </Menu>
    </div>
  )

  return (
    <nav className={classes.drawer} aria-label="main menu">
      <Drawer
        {...rest}
        container={container}
        variant={variant}
        anchor={'left'}
        open={open}
        onClose={onCloseDrawer}
        className="drawer"
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {userProfile}
        {drawer}
      </Drawer>
    </nav>
  )
}
